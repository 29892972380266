import { Dialog, Transition } from '@headlessui/react';
import { InformationCircleIcon, XIcon } from '@heroicons/react/solid';
import { Fragment, useState } from 'react';
import Button from '../Button';
import IconButton from '../Button/IconButton';

export default function ImagePreviewModal({ isOpen, setIsOpen, image }) {
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 flex items-start justify-center overflow-y-auto align-top antialiased"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out transform duration-500"
              enterFrom="opacity-0 translate-y-96"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="z-10 my-8 inline-block w-full max-w-3xl transform overflow-hidden p-8 text-left align-middle transition-all">
                <div className="relative flex flex-col items-center justify-center">
                  <button
                    onClick={() => closeModal()}
                    className="absolute top-2 right-2 rounded-full bg-gray-900 p-2 text-white duration-200 hover:bg-gray-800 focus:outline-none"
                  >
                    <XIcon className="h-5 w-5" />
                  </button>

                  <img src={image} className={'h-auto w-full rounded-2xl'} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
