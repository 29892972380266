import Button from '../Button';
import IconButton from '../Button/IconButton';
import TSSimple from '../Icons/ts-simple';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { useEffect, useState } from 'react';
import { useRouter, useSocialBlogContext } from '../SocialBlogContext';
import Image from 'next/image';

export default function PostTopBar({
  customColors = false,
  type = 'dark',
  photoUrl,
  galleryName,
  displayName,
  creatorMeta,
}) {
  const [uid, setUid] = useState(null);
  const [loggedInUserImage, setLoggedInUserImage] = useState(null);

  const [affiliateName, setAffiliateName] = useState('');

  const router = useRouter();
  const { isCustomDomain, hostname } = useSocialBlogContext();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setUid(user.uid);
        getLoggedInUserImage(user.uid);
      } else {
        setUid(null);
      }
    });
  }, []);

  useEffect(() => {
    getAffiliate();
  }, [creatorMeta]);

  const getAffiliate = () => {
    const affiliateName = creatorMeta.affiliate
      ? creatorMeta.affiliate.name
      : null;
    const active = creatorMeta.affiliate ? creatorMeta.affiliate.active : false;
    if (affiliateName && active) {
      setAffiliateName(affiliateName);
    }
  };

  const getLoggedInUserImage = (uid) => {
    if (uid) {
      //get image
      const db = firebase.database();
      const ref = db.ref(`users/${uid}`).child('gallery/username');
      ref.once('value', function (snapshot) {
        if (snapshot.val()) {
          const username = snapshot.val();
          const ref2 = db
            .ref('gallery')
            .child(username)
            .child(uid)
            .child('meta')
            .child('image');
          ref2.once('value', function (snapshot) {
            const image = snapshot.val();
            setLoggedInUserImage(image);
          });
        }
      });
    }
  };

  return customColors ? (
    <>
      {/* <div
      className={`sticky top-0 z-10 flex w-full items-center justify-between bg-opacity-75 p-3 backdrop-blur-xl backdrop-filter ${
        customTheme ? null : themes.colorTemplates[colorID - 1].background
      }`}
    >
      <div className="flex flex-row items-center justify-start">
        <IconButton
          click={() => router.push('/')}
          backgroundStyle={{ background: customTheme ? titleColor : null }}
          textStyle={{ color: customTheme ? bgColor : null }}
          color={`${
            customTheme ? null : themes.colorTemplates[colorID - 1].buttonBG
          } bg-opacity-0 group group-hover:bg-opacity-10`}
          customText={`${
            customTheme ? null : themes.colorTemplates[colorID - 1].title
          }`}
          prefix={<TSSimple className={'h-6 w-6 fill-current'} />}
        ></IconButton>
      </div>
      {firebase.auth().currentUser ? (
        loggedInUserImage ? (
          <Button
            click={() => router.push('/library')}
            backgroundStyle={{ background: customTheme ? titleColor : null }}
            textStyle={{ color: customTheme ? bgColor : null }}
            color={
              customTheme ? null : themes.colorTemplates[colorID - 1].buttonBG
            }
            customText={
              customTheme ? null : themes.colorTemplates[colorID - 1].buttonText
            }
            variant={'primary'}
            size={'image'}
            prefix={
              <Image
                src={loggedInUserImage}
                width={32}
                height={32}
                className={'rounded-xl'}
              />
            }
          >
            Open Typeshare
          </Button>
        ) : (
          <Button
            click={() => router.push('/library')}
            backgroundStyle={{ background: customTheme ? titleColor : null }}
            textStyle={{ color: customTheme ? bgColor : null }}
            color={
              customTheme ? null : themes.colorTemplates[colorID - 1].buttonBG
            }
            customText={
              customTheme ? null : themes.colorTemplates[colorID - 1].buttonText
            }
            variant={'primary'}
          >
            Open Typeshare
          </Button>
        )
      ) : (
        <div className="flex flex-row items-center justify-center space-x-4">
          <button
            onClick={() => router.push('/')}
            style={{ color: customTheme ? textColor : null }}
            className={`${
              customTheme ? 'Custom' : themes.colorTemplates[colorID - 1].name
            } group font-primary font-medium`}
          >
            <p className="duration-200 group-hover:text-opacity-60">Login</p>
          </button>
          <Button
            click={() =>
              affiliateName
                ? router.push({ pathname: '/', query: { via: affiliateName } })
                : router.push('/')
            }
            backgroundStyle={{ background: customTheme ? titleColor : null }}
            textStyle={{ color: customTheme ? bgColor : null }}
            color={
              customTheme ? null : themes.colorTemplates[colorID - 1].buttonBG
            }
            customText={
              customTheme ? null : themes.colorTemplates[colorID - 1].buttonText
            }
            variant={'primary'}
          >
            Join Typeshare
          </Button>
        </div>
      )}
    </div> */}
    </>
  ) : (
    <div
      className={`z-10 flex w-full items-center justify-between p-3 ${
        type === 'blog'
          ? null
          : 'sticky top-0 bg-opacity-75 backdrop-blur-xl backdrop-filter'
      }`}
    >
      <div className="flex flex-row items-center justify-start">
        <IconButton
          click={() => router.push('/')}
          color={`group ${
            type === 'blog'
              ? 'group-hover:bg-white group-hover:bg-opacity-10 text-gray-100'
              : 'group-hover:bg-gray-100 text-gray-700'
          } `}
          prefix={
            <TSSimple
              className={`h-6 w-6  fill-current ${
                type === 'blog' ? 'text-gray-100' : ' text-gray-700'
              } `}
            />
          }
        ></IconButton>

        {type === 'blog' ? null : (
          <div className="ml-2 mr-5 hidden h-8 w-[2px] rotate-12 transform rounded-full bg-gray-100 sm:flex" />
        )}

        {type === 'blog' ? null : (
          <button
            onClick={() =>
              router.push({ pathname: '/[slug]', query: { slug: galleryName } })
            }
            className="group hidden flex-row items-center justify-start space-x-2 sm:flex"
          >
            {photoUrl ? (
              <Image
                width={40}
                height={40}
                className="rounded-full"
                src={photoUrl}
              />
            ) : (
              <div className="h-10 w-10 animate-pulse rounded-full bg-gray-200" />
            )}
            <div className="flex flex-col -space-y-1">
              <div className="flex flex-row items-center justify-start space-x-1">
                {displayName ? (
                  <p className="text-left font-primary font-semibold text-gray-900 duration-200 group-hover:text-blue-500">
                    {displayName}
                  </p>
                ) : (
                  <div className="mb-1 h-6 w-16 animate-pulse rounded-md bg-gray-200" />
                )}
              </div>
              <div className="flex flex-row items-center justify-start space-x-2">
                {isCustomDomain ? (
                  <p className="text-left font-sans text-sm font-medium text-gray-500">
                    {hostname}
                  </p>
                ) : galleryName ? (
                  <p className="text-left font-sans text-sm font-medium text-gray-500">
                    typeshare.co/{galleryName}
                  </p>
                ) : (
                  <div className="h-3 w-24 animate-pulse rounded-md bg-gray-200" />
                )}
              </div>
            </div>
          </button>
        )}
      </div>
      {firebase.auth().currentUser ? (
        loggedInUserImage ? (
          <Button
            click={() => router.push('/library')}
            variant={'primary'}
            size={'image'}
            prefix={
              <Image
                width={32}
                height={32}
                src={loggedInUserImage}
                className={'rounded-xl'}
              />
            }
          >
            Open Typeshare
          </Button>
        ) : (
          <Button click={() => router.push('/library')} variant={'primary'}>
            Open Typeshare
          </Button>
        )
      ) : (
        <div className="flex flex-row items-center justify-center space-x-4">
          <button
            onClick={() => router.push('/')}
            className={`${
              type === 'blog' ? 'text-white' : 'text-blue-500'
            } group font-primary font-medium`}
          >
            <p className="duration-200 group-hover:text-opacity-60">Login</p>
          </button>
          <Button
            click={() =>
              affiliateName
                ? router.push({ pathname: '/', query: { via: affiliateName } })
                : router.push('/')
            }
            variant={'primary'}
          >
            Join Typeshare
          </Button>
        </div>
      )}
    </div>
  );
}
