export default function VerifiedIcon({ className }) {
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 14C6.01016 14 5.13516 13.4914 4.6293 12.723C3.72422 12.909 2.75078 12.652 2.05078 11.9492C1.35078 11.2492 1.09102 10.2703 1.27695 9.3707C0.511328 8.86758 0 7.99258 0 7C0 6.01016 0.508594 5.13516 1.27695 4.6293C1.09102 3.73242 1.34805 2.75078 2.05078 2.05078C2.75078 1.35078 3.72969 1.09102 4.6293 1.27695C5.13242 0.511328 6.00742 0 7 0C7.99258 0 8.86484 0.511328 9.3707 1.27695C10.2676 1.09102 11.2492 1.34805 11.9492 2.05078C12.6492 2.75078 12.909 3.72969 12.723 4.6293C13.4859 5.12969 14 6.00195 14 7C14 7.98984 13.4914 8.86484 12.723 9.3707C12.909 10.2676 12.652 11.2492 11.9492 11.9492C11.2492 12.6492 10.2758 12.909 9.3707 12.723C8.86758 13.4859 7.99531 14 7 14ZM5.32656 11.0414C5.5043 11.5172 5.76133 12.6875 7 12.6875C8.20039 12.6875 8.46289 11.6047 8.67344 11.0414C9.50742 11.4187 10.2539 11.7879 11.0195 11.0223C11.8672 10.1746 11.2875 9.22031 11.0387 8.67617C11.5145 8.49844 12.6848 8.24141 12.6848 7.00273C12.6848 5.80234 11.602 5.53984 11.0387 5.3293C11.2492 4.86719 11.8945 3.8582 11.0195 2.9832C10.1719 2.13555 9.21758 2.71523 8.67344 2.96406C8.4957 2.48281 8.23867 1.3125 7 1.3125C5.79961 1.3125 5.53711 2.39531 5.32656 2.95859C4.86445 2.74805 3.85547 2.10273 2.98047 2.97773C2.13281 3.82539 2.7125 4.77969 2.96133 5.32383C2.48281 5.5043 1.3125 5.76133 1.3125 7C1.3125 8.20039 2.39531 8.46289 2.95859 8.67344C2.74805 9.13555 2.10273 10.1445 2.97773 11.0195C3.82539 11.8672 4.74961 11.3039 5.32656 11.0414Z"
        fill="currentColor"
      />
      <circle cx="7" cy="7" r="6" fill="currentColor" />
      <g clipPath="url(#clip0_54_3)">
        <path
          d="M9.45851 4.64114L5.86221 8.23745L4.54079 6.91603C4.47673 6.85197 4.37285 6.85197 4.30877 6.91603L3.92207 7.30273C3.85801 7.36679 3.85801 7.47067 3.92207 7.53475L5.74619 9.35887C5.81025 9.42293 5.91413 9.42293 5.97821 9.35887L10.0772 5.25986C10.1413 5.1958 10.1413 5.09192 10.0772 5.02784L9.69052 4.64114C9.62645 4.57708 9.52258 4.57708 9.45851 4.64114V4.64114Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_54_3">
          <rect
            width="7"
            height="7"
            fill="white"
            transform="translate(3.5 3.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
