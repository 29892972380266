import { useRouter } from '../SocialBlogContext';
import Button from '../Button';
import VerifiedIcon from '../Icons/verified';

import ImagePreviewModal from '../NewModals/image-preview';

import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/database';
import Image from 'next/image';
import { useEffect, useState } from 'react';

export default function UserSidebar({
  creatorMeta,
  customTheme,
  bgColor,
  titleColor,
  customColors,
  themes,
  fontID,
  colorID,
  footerBGColor,
  footerTextColor,
  currentUser,
}) {
  const [collections, setCollections] = useState([]);
  const [imagePreview, setImagePreview] = useState(false);
  const [activeBadge, setActiveBadge] = useState('');

  useEffect(() => {
    creatorMeta.creatorID ? getCollections() : null;
  }, [creatorMeta]);

  const getCollections = () => {
    if (creatorMeta.creatorID) {
      firebase
        .database()
        .ref(`gallery/${creatorMeta.slug}/${creatorMeta.userID}/collections`)
        .once('value', (snapshot) => {
          if (snapshot.exists()) {
            //loop through collections
            const collections = [];
            snapshot.forEach((collection) => {
              collections.push(collection.val());
            });
            setCollections(collections);
          }
        });
    }
  };

  const viewBadge = (badge) => {
    setActiveBadge(badge);
    setImagePreview(true);
  };

  const router = useRouter();
  return (
    <div
      style={{ background: customTheme ? footerBGColor : null }}
      className={` top-32 ${
        customColors
          ? customTheme
            ? null
            : themes.colorTemplates[colorID - 1].footer.background
          : 'border-2 border-gray-100 bg-gray-50'
      } sticky flex w-full   max-w-sm flex-col overflow-hidden rounded-2xl`}
    >
      <ImagePreviewModal
        isOpen={imagePreview}
        setIsOpen={setImagePreview}
        image={activeBadge}
      />

      <Image
        height={160}
        width={200}
        className="h-40 w-full bg-gradient-to-br from-blue-300 to-indigo-200 object-cover"
        priority
        src={
          creatorMeta.coverImage
            ? creatorMeta.coverImage
            : '/default-header.png'
        }
      />

      <div className="relative z-10 -mt-14 flex flex-col items-start justify-start space-y-8 p-6">
        <div className="flex flex-col ">
          <div className="flex flex-col flex-nowrap items-start justify-start">
            <div
              style={{ borderColor: customTheme ? footerBGColor : null }}
              className={`h-16 w-16 overflow-hidden rounded-full border-4 ${
                customColors
                  ? customTheme
                    ? null
                    : 'border-gray-50'
                  : 'border-gray-50'
              }`}
            >
              <Image width={64} height={64} src={creatorMeta.image} />
            </div>

            <div className="flex flex-row flex-nowrap items-center justify-center space-x-1 overflow-hidden font-sans text-base text-gray-500">
              <p
                style={{ color: customTheme ? footerTextColor : null }}
                className={`flex-nowrap overflow-hidden overflow-ellipsis whitespace-nowrap font-primary text-xl font-bold ${
                  customColors
                    ? customTheme
                      ? null
                      : themes.colorTemplates[colorID - 1].footer.text
                    : 'text-gray-900'
                }`}
              >
                {creatorMeta.displayName}
              </p>
              {/* {creatorMeta.verified ? <VerifiedIcon className={"w-5 h-5 text-blue-400 fill-current"} /> : null} */}
              {/*        <p className='text-gray-500 font-medium text-xs'>typeshare.co/sam</p> */}
            </div>
          </div>
          <div
            style={{ color: customTheme ? footerTextColor : null }}
            className={` mb-4 mt-2 text-sm ${
              customColors
                ? customTheme
                  ? null
                  : themes.colorTemplates[colorID - 1].footer.text
                : 'text-gray-900'
            }`}
          >
            <p>{creatorMeta.bio}</p>
          </div>
          <Button
            click={() =>
              router.push({
                pathname: '/[slug]',
                query: { slug: creatorMeta.slug },
              })
            }
            backgroundStyle={{ background: customTheme ? titleColor : null }}
            textStyle={{ color: customTheme ? bgColor : null }}
            color={
              customColors
                ? customTheme
                  ? null
                  : themes.colorTemplates[colorID - 1].buttonBG
                : null
            }
            customText={
              customColors
                ? customTheme
                  ? null
                  : themes.colorTemplates[colorID - 1].buttonText
                : null
            }
            size={'small'}
            variant={'primary'}
            width={'full'}
          >
            View Social Blog
          </Button>
        </div>
        <div className="flex flex-col space-y-4">
          {collections.length > 0 ? (
            <div className="flex flex-col space-y-2">
              <div
                style={{ color: customTheme ? footerTextColor : null }}
                className={`${
                  customColors
                    ? customTheme
                      ? null
                      : themes.colorTemplates[colorID - 1].footer.text
                    : 'text-gray-900'
                } `}
              >
                <p className="text-xs font-semibold uppercase">Collections</p>
              </div>

              <div className="flex flex-row flex-wrap gap-2">
                {collections.map((collection, index) => (
                  <Button
                    click={() =>
                      router.push({
                        pathname: '/[slug]/collections/[collection]',
                        query: {
                          slug: creatorMeta.slug,
                          collection: collection.meta.slug,
                        },
                      })
                    }
                    size={'mini'}
                    backgroundStyle={{
                      background: customTheme ? titleColor : null,
                    }}
                    textStyle={{ color: customTheme ? bgColor : null }}
                    color={
                      customColors
                        ? customTheme
                          ? null
                          : themes.colorTemplates[colorID - 1].buttonBG
                        : 'bg-gray-100 group-hover:bg-gray-200'
                    }
                    customText={
                      customColors
                        ? customTheme
                          ? null
                          : themes.colorTemplates[colorID - 1].buttonText
                        : 'text-gray-500'
                    }
                  >
                    {collection.meta.title}
                  </Button>
                ))}
              </div>
            </div>
          ) : null}

          <div
            className={`${
              creatorMeta.badges !== undefined ? 'flex' : 'hidden'
            } flex-col space-y-2`}
          >
            <div
              style={{ color: customTheme ? footerTextColor : null }}
              className={` ${
                customColors
                  ? customTheme
                    ? null
                    : themes.colorTemplates[colorID - 1].name
                  : 'text-gray-900'
              } `}
            >
              <p className="text-xs font-semibold uppercase">Badges</p>
            </div>

            <div className={'flex flex-row flex-wrap items-center gap-2'}>
              {creatorMeta.badges !== undefined &&
              creatorMeta.badges !== null ? (
                creatorMeta.badges.earlyAdopterPin ? (
                  <button onClick={() => viewBadge('/early-adopter-pin.png')}>
                    <img className="h-10 w-10" src={'/early-adopter-pin.png'} />
                  </button>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
